import './style.css'

const PriceTable = () => {
    return (
        <div>

            <div className="price__column" id="generic_price_table">

                {/* есть макет  */}
                <section className="section__price">

                    <div class="row">
                        <div class="col-md-4">

                            <div class="generic_content clearfix">

                                <div class="generic_head_price clearfix">

                                    <div class="generic_head_content clearfix">

                                        <div class="head_bg"></div>
                                        <div class="head">
                                            <span>У вас <b>есть</b> макет</span>
                                        </div>

                                    </div>

                                    <div class="generic_price_tag clearfix">
                                        <span class="price">

                                            <span class="price">
                                                <span class="currency">3500</span>
                                                <span class="cent">рублей</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div class="generic_feature_list">
                                    <ul>
                                        <li><span className="price__list-title">Адаптивный сайт</span>
                                            <br /><br />  Сайт будет удобен на всех типах устройств.</li>

                                        <li><span className="price__list-title">Структура сайта</span>
                                            <br /><br /> <b>Три основных блока</b>: шапка, основная часть с контентом и подвал. </li>

                                        <li><span className="price__list-title">Меню навигациии</span>
                                            <br /><br /> Удобное и интуитивно понятное меню навигации. <br /> Всплывающее меню для мобильных устройств</li>

                                        <li><span className="price__list-title">Количество страниц</span>
                                            <br /><br />Одностороничный сайт</li>

                                        <li><span className="price__list-title">Сопровождение сайта</span>
                                            <br /><br /> После завершения разработки вам предоставляется бесплатное сопровождение в течении полугода, включая внесение изменений и доработок</li>


                                    </ul>
                                </div>



                            </div>
                        </div>

                    </div>

                </section>

                {/* нет макета */}
                <section className="section__price">

                    <div class="row">
                        <div class="col-md-4">

                            <div class="generic_content clearfix">

                                <div class="generic_head_price clearfix">

                                    <div class="generic_head_content clearfix">

                                        <div class="head_bg"></div>
                                        <div class="head">
                                            <span>У вас <b>нет</b> макета</span>
                                        </div>

                                    </div>

                                    <div class="generic_price_tag clearfix">
                                        <span class="price">

                                            <span class="price">
                                                <span class="currency">5000</span>
                                                <span class="cent">рублей</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div class="generic_feature_list">
                                    <ul>
                                        <li><span className="price__list-title">Адаптивный сайт</span>
                                            <br /><br /> Сайт будет удобен на всех типах устройств</li>

                                        <li><span className="price__list-title">Структура сайта</span>
                                            <br /><br /> <b>Три основных блока</b>: шапка, основная часть с контентом и подвал</li>

                                        <li><span className="price__list-title">Меню навигациии</span>
                                            <br /><br /> Удобное и интуитивно понятное меню навигации. <br /> Всплывающее меню для мобильных устройств</li>

                                        <li><span className="price__list-title">Количество страниц</span>
                                            <br /><br />Одностороничный сайт</li>

                                        <li><span className="price__list-title">Сопровождение сайта</span>
                                            <br /><br /> После завершения разработки вам предоставляется бесплатное сопровождение в течении полугода, включая внесение изменений и доработок</li>

                                        <li><span className="price__list-title">Макет и дизайн</span>
                                            <br /><br /> Помогу подобрать макет и дизай под ваши запросы,
                                            Взять уже готовый кейс и адаптировать под вас</li>

                                    </ul>
                                </div>



                            </div>
                        </div>

                    </div>

                </section>

                {/* допы */}
                <section className="section__price">

                    <div class="row">
                        <div class="col-md-4">

                            <div class="generic_content clearfix">

                                <div class="generic_head_price clearfix">

                                    <div class="generic_head_content clearfix">

                                        <div class="head_bg"></div>
                                        <div class="head">
                                            <span>Дополнительные услуги</span>
                                        </div>

                                    </div>

                                    <div class="generic_price_tag clearfix">
                                        <span class="price">

                                            <span class="price">
                                                <span class="cent">Не входит в стоимость основных услуг</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div class="generic_feature_list">
                                    <ul>


                                        <li><span className="price__list-title">Установка на хостинг и регистрация доменного имени</span>:
                                            <br /><span className="price__main"> от 1000 руб.</span>
                                            <br /><br /> Установка сайта на мой хостинг на <b>1 год</b></li>

                                        <li><span className="price__list-title">Дополнительный блок</span>:
                                            <br /><span className="price__main"> от 100 руб.</span>
                                            <br /><br /> Добавление дополнительных блоков на страницу</li>

                                        <li><span className="price__list-title">Слайдер</span>:
                                            <br /><span className="price__main"> от 200 руб.</span>
                                            <br /><br />Красивый анимированный слайдер для улучшения внешнего вида сайта интерактивности</li>

                                        <li><span className="price__list-title">Дополнительные страницы</span>:
                                            <br /><span className="price__main"> 500 руб.</span>
                                            <br /><br />Создание дополнительных страниц для вашего сайта</li>

                                        <li><span className="price__list-title">Темная тема</span>:
                                            <br /> <span className="price__main"> 600 руб.</span>
                                            <br /><br />Кнопка с темной темой для элегантного и стильного визуального оформления</li>

                                        <li><span className="price__list-title">Сопровождение сайта</span>:
                                            <br /> <span className="price__main"> 200 руб./мес</span>
                                            <br /><br />Внесение изменений, доработка</li>

                                    </ul>
                                </div>



                            </div>
                        </div>

                    </div>

                </section>
            </div>
        </div>
    );
}

export default PriceTable;