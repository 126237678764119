import me from './../img/me.jpg'
import Header from '../components/header/Header';


const Home = () => {
    return (
        <>
            <Header />

            <main className="section">

                <div className="container">
                    <h1 className="title-1">Обо мне</h1>

                    <div className="about">

                        <img src={me} alt="мое фото" className="about-img" />

                        <div className="about-text">
                            <p> Я верстальщик с опытом работы, специализирующийся на создании интерактивных и привлекательных сайтов. Начав с базовых знаний HTML, CSS и JavaScript, я постоянно развиваю свои навыки, используя современные технологии, такие как Gulp и SCSS. Это позволяет мне автоматизировать рутинные задачи и повышать эффективность.
                            </p>

                            <p>На текущий момент являюсь верстальщиком в компании <b>"DatsTeam"</b>, где работаю в одном из проектов. Мой опыт позволяет мне создавать уникальные, функциональные и удобные для пользователей веб-сайты, полностью отвечающие требованиям клиентов и соответствующие современным стандартам веб-разработки. Я стремлюсь обеспечить наилучший результат для каждого проекта.
                            </p>

                            <p>Моя цель - воплотить ваши идеи, создав веб-сайт, который будет заметным, успешным и превзойдет ваши ожидания. Если вы ищете профессионала в области верстки, готового предоставить качественный результат, обращайтесь ко мне. Вместе мы создадим нечто удивительное!</p>
                        </div>
                    </div>

                    <ul className="content-list">
                        <li className="content-list__item">
                            <h2 className="title-2">Навыки</h2>
                            <p>HTML, CSS, JavaScript</p>
                        </li>
                        <li className="content-list__item">
                            <h2 className="title-2">Инструменты</h2>
                            <p>Gulp, SCSS, Webpack</p>
                        </li>
                    </ul>
                </div>
            </main>
        </>
    );
}

export default Home;